import { Injectable, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTableDataSource } from '@angular/material/table';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { TableColumnDef } from '../../../../../../../projects/grid-views/src/lib/table';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { dataTypes, dimensions, fieldTypes } from './data-fields.data';
import { DataManagementService } from '../../data-management.service';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export interface DataTableConfig {
    displayedColumns: Array<TableColumnDef>;
    dataSource: MatTableDataSource<any>;
}

export function noCapitalLetters(control: AbstractControl): ValidationErrors {
    const forbidden = /^[a-zA-Z0-9]*$/.test(control.value);
    return forbidden ? { noCapitalLetters: { value: control.value } } : null;
}

@Injectable({
    providedIn: 'root',
})
export class DataFieldsService {
    private _tableConfig: Signal<DataTableConfig>;

    private _translocoContent: Signal<Translation>;
    private _errors: Signal<Translation>;

    private _fieldForm: Signal<FormlyFieldConfig[]>;

    constructor(
        private _translocoService: TranslocoService,
        private _dataManagementService: DataManagementService
    ) {
        this._translocoContent = toSignal(this._translocoService.selectTranslation('data'));
        this._errors = toSignal(this._translocoService.selectTranslateObject('errors'));

        this._setFieldForm();
        this._setTableConfig();
    }

    get tableConfig() {
        return this._tableConfig();
    }

    get fieldForm(): FormlyFieldConfig[] {
        return this._fieldForm();
    }

    private _setTableConfig() {
        this._tableConfig = computed(() => {
            let dataSource = [];
            if (this._dataManagementService.selectedDataTable()?.fields) {
                dataSource = [
                    ...this._dataManagementService.selectedDataTable()?.fields.map(entry => {
                        return {
                            icon: '',
                            type: entry.dtype,
                            ftype: entry.ftype,
                            desc: `${entry.no}&nbsp;&nbsp;&nbsp;${entry.titel}<br />${entry.description}`,
                        };
                    }),
                ];
            } else {
                dataSource = [];
            }
            return {
                displayedColumns: [
                    {
                        columnKey: 'icon',
                        columnName: '',
                    },
                    {
                        columnKey: 'desc',
                        columnName: this._translocoContent()['description'],
                    },
                    {
                        columnKey: 'type',
                        columnName: this._translocoContent()['dataType'],
                        enableSorting: true,
                    },
                ],
                dataSource: new MatTableDataSource(dataSource),
            };
        });
    }

    private _setFieldForm() {
        this._fieldForm = computed(() => [
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'input',
                        key: 'titel',
                        props: {
                            label: this._translocoContent()['titel'],
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                        validators: ['noCapitalLetters'],
                    },
                    {
                        className: 'flex-1',
                        type: 'input',
                        key: 'fieldName',
                        props: {
                            label: this._translocoContent()['fieldName'],
                            required: true,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'dtype',
                        props: {
                            label: this._translocoContent()['type'],
                            required: true,
                            options: dataTypes,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                ],
            },
            {
                className: 'flex-1',
                type: 'input',
                key: 'description',
                props: {
                    label: this._translocoContent()['description'],
                    required: true,
                },
                validation: this.getRequiredValidation(this._errors()['required']),
            },
            {
                fieldGroupClassName: 'flex flex-row gap-2 flex-wrap',
                fieldGroup: [
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'ftype',
                        props: {
                            label: this._translocoContent()['fieldType'],
                            required: true,
                            options: fieldTypes,
                        },
                        validation: this.getRequiredValidation(this._errors()['required']),
                    },
                    {
                        className: 'flex-1',
                        type: 'select',
                        key: 'dimension',
                        disabled: true,
                        defaultValue: '',
                        props: {
                            label: this._translocoContent()['dimension'],
                            options: dimensions,
                        },
                    },
                ],
            },
        ]);
    }

    getRequiredValidation(message: string): any {
        return {
            messages: {
                required: message,
            },
        };
    }
}
